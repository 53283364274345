exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acceptable-use-policy-js": () => import("./../../../src/pages/acceptable-use-policy.js" /* webpackChunkName: "component---src-pages-acceptable-use-policy-js" */),
  "component---src-pages-blog-change-incident-communications-together-js": () => import("./../../../src/pages/blog/change-incident-communications-together.js" /* webpackChunkName: "component---src-pages-blog-change-incident-communications-together-js" */),
  "component---src-pages-blog-dont-wait-communicate-js": () => import("./../../../src/pages/blog/dont-wait-communicate.js" /* webpackChunkName: "component---src-pages-blog-dont-wait-communicate-js" */),
  "component---src-pages-blog-false-alarms-in-incident-management-js": () => import("./../../../src/pages/blog/false-alarms-in-incident-management.js" /* webpackChunkName: "component---src-pages-blog-false-alarms-in-incident-management-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-promote-status-page-js": () => import("./../../../src/pages/blog/promote-status-page.js" /* webpackChunkName: "component---src-pages-blog-promote-status-page-js" */),
  "component---src-pages-blog-why-you-need-a-status-page-js": () => import("./../../../src/pages/blog/why-you-need-a-status-page.js" /* webpackChunkName: "component---src-pages-blog-why-you-need-a-status-page-js" */),
  "component---src-pages-compare-uptime-sorry-vs-statuspage-js": () => import("./../../../src/pages/compare/uptime-sorry-vs-statuspage.js" /* webpackChunkName: "component---src-pages-compare-uptime-sorry-vs-statuspage-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-incident-communications-101-js": () => import("./../../../src/pages/incident-communications-101.js" /* webpackChunkName: "component---src-pages-incident-communications-101-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-index-js": () => import("./../../../src/pages/integrations/index.js" /* webpackChunkName: "component---src-pages-integrations-index-js" */),
  "component---src-pages-integrations-status-badge-js": () => import("./../../../src/pages/integrations/status-badge.js" /* webpackChunkName: "component---src-pages-integrations-status-badge-js" */),
  "component---src-pages-intercom-messenger-app-js": () => import("./../../../src/pages/intercom-messenger-app.js" /* webpackChunkName: "component---src-pages-intercom-messenger-app-js" */),
  "component---src-pages-metrics-js": () => import("./../../../src/pages/metrics.js" /* webpackChunkName: "component---src-pages-metrics-js" */),
  "component---src-pages-notices-general-js": () => import("./../../../src/pages/notices/general.js" /* webpackChunkName: "component---src-pages-notices-general-js" */),
  "component---src-pages-notices-incidents-js": () => import("./../../../src/pages/notices/incidents.js" /* webpackChunkName: "component---src-pages-notices-incidents-js" */),
  "component---src-pages-notices-maintenance-js": () => import("./../../../src/pages/notices/maintenance.js" /* webpackChunkName: "component---src-pages-notices-maintenance-js" */),
  "component---src-pages-powered-by-js": () => import("./../../../src/pages/powered-by.js" /* webpackChunkName: "component---src-pages-powered-by-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-private-status-page-js": () => import("./../../../src/pages/private-status-page.js" /* webpackChunkName: "component---src-pages-private-status-page-js" */),
  "component---src-pages-public-status-page-js": () => import("./../../../src/pages/public-status-page.js" /* webpackChunkName: "component---src-pages-public-status-page-js" */),
  "component---src-pages-security-and-performance-js": () => import("./../../../src/pages/security-and-performance.js" /* webpackChunkName: "component---src-pages-security-and-performance-js" */),
  "component---src-pages-simple-status-page-js": () => import("./../../../src/pages/simple-status-page.js" /* webpackChunkName: "component---src-pages-simple-status-page-js" */),
  "component---src-pages-status-api-js": () => import("./../../../src/pages/status-api.js" /* webpackChunkName: "component---src-pages-status-api-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-testimonials-20240723-fortra-cybersecurity-status-js": () => import("./../../../src/pages/testimonials/20240723-fortra-cybersecurity-status.js" /* webpackChunkName: "component---src-pages-testimonials-20240723-fortra-cybersecurity-status-js" */),
  "component---src-pages-testimonials-20240910-postmark-email-delivery-status-js": () => import("./../../../src/pages/testimonials/20240910-postmark-email-delivery-status.js" /* webpackChunkName: "component---src-pages-testimonials-20240910-postmark-email-delivery-status-js" */),
  "component---src-pages-vulnerability-disclosure-js": () => import("./../../../src/pages/vulnerability-disclosure.js" /* webpackChunkName: "component---src-pages-vulnerability-disclosure-js" */)
}

